import {Component} from '@angular/core';
import {MsalService} from "@azure/msal-angular";
import {Router} from "@angular/router";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {

  constructor(private msalService: MsalService, private router: Router) {
  }

  ngOnInit(): void {

    (async () => {
      try {
        await this.msalService.instance.initialize();
        this.msalService.instance.handleRedirectPromise().then(
          res => {
            if (res != null && res.account != null) {
              this.msalService.instance.setActiveAccount(res.account)
              this.router.navigate(['/matches']);
            }
          }
        );
      } catch (error) {
        console.error('MSAL initialization failed', error);
      }
    })();
  }

  login() {
    this.msalService.loginRedirect();
  }

  isLoggedIn(): boolean {
    return this.msalService.instance.getActiveAccount()!= null
  }
}
