import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpResponse
} from '@angular/common/http';
import {Injectable} from "@angular/core";
import {LoadingService} from "../services/LoadingService";
import {Observable} from "rxjs";
import {catchError, finalize, map} from 'rxjs/operators'
import {environment} from "../../environments/environment";

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {

  constructor(
    private _loading: LoadingService
  ) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    console.log("network intercept fired");
    this._loading.setLoading(true, request.url);
    // return next.handle(request)
    //   .pipe(catchError((err) => {
    //     this._loading.setLoading(false, request.url);
    //     return err;
    //   }))
    //   .pipe(finalize(() => {
    //     this._loading.setLoading(false, request.url);
    //   }));

    if (request.url.includes('/api/')) {
      const headers = request.headers.set('X-API-KEY', `${environment.apiKey}`);
      const apiKeyReq = request.clone({headers});

      return next.handle(apiKeyReq).pipe(
        finalize(() => {
          this._loading.setLoading(false, request.url);
        })
      );
    }

    return next.handle(request).pipe(
      finalize(() => {
        this._loading.setLoading(false, request.url);
      })
    );
  }
}
