<mat-card>
  <mat-card-header>
    <div class="flex flex-row w-full">
      <div class="flex flex-col w-full">
        <div class="flex flex-row justify-center items-center gap-4">
          <div class="font-semibold text-lg">Create New Tournament</div>
        </div>
      </div>
    </div>
  </mat-card-header>
  <mat-card-content>
    <div class="grid grid-cols-2 gap-3 p-10">
      <mat-form-field appearance="outline">
        <mat-label>Title</mat-label>
        <input matInput placeholder="Title" [(ngModel)]="title">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Title Abbreviation</mat-label>
        <input matInput placeholder="Title Abbreviation" [(ngModel)]="titleAbbreviation" required>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Start Date</mat-label>
        <input matInput [matDatepicker]="startDatepicker" [(ngModel)]="startDate">
        <mat-datepicker-toggle matSuffix [for]="startDatepicker"></mat-datepicker-toggle>
        <mat-datepicker #startDatepicker></mat-datepicker>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>End Date</mat-label>
        <input matInput [matDatepicker]="endDatepicker" [(ngModel)]="endDate">
        <mat-datepicker-toggle matSuffix [for]="endDatepicker"></mat-datepicker-toggle>
        <mat-datepicker #endDatepicker></mat-datepicker>
      </mat-form-field>
      <button mat-raised-button (click)="save()" color="accent" class="float-right btn ">Save</button>
      <button mat-raised-button (click)="cancel()" color="warn" class="float-right btn ">Cancel</button>
    </div>
  </mat-card-content>
</mat-card>
