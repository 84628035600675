import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {environment} from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ReportingService {
  url = environment.apiUrl + "reports/";

  constructor(
    private http: HttpClient
  ) {
  }

  getOnArrivialTeamSheet(matchId: number, teamId: number) {
    return this.http.get<Blob>(this.url + "onArrivalTeamSheet?matchId=" + matchId + "&teamId=" + teamId, {
      observe: 'response',
      responseType: 'blob' as 'json'
    });
  }

  getMatchReport(matchId: number) {
    return this.http.get<Blob>(this.url + "matchReport?matchId=" + matchId, {
      observe: 'response',
      responseType: 'blob' as 'json'
    });
  }

  getTournamentCodesReport(tournamentId: number) {
    return this.http.get<Blob>(this.url + "tournamentLoginCodes?tournamentId=" + tournamentId, {
      observe: 'response',
      responseType: 'blob' as 'json'
    });
  }
}
