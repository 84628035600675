import {DOCUMENT} from '@angular/common';
import {Component, Inject, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {MsalService} from "@azure/msal-angular";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(
    private router: Router,
    private msalService: MsalService,
    @Inject(DOCUMENT) private doc: Document
  ) {
  }

  ngOnInit(): void {
    this.router.navigate(['matches']);
  }

  goToMatches(): void {
    console.log("going to matches");
    this.router.navigate(['matches']);
  }

  goToRimsTournaments(): void {
    this.router.navigate(['matches']);
  }

  goToTeamMappings(): void {
    this.router.navigate(['matches']);
  }

  logout(): void {
    this.msalService.logout();
  }
}
